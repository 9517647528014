import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import { map } from 'rxjs';

import { CostCenterListItem } from '@mp/organization-master-data/cost-centers/domain';
import { NotificationService, PageResponse } from '@mp/shared/data-access';

import { CostCentersService } from '../services';

import { CostCentersActions } from './cost-centers.actions';

@Injectable()
export class CostCentersEffects {
  fetchCostCenters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CostCentersActions.fetchCostCenters),
      fetch({
        run: ({ searchTerm }) =>
          this.costCentersService
            .fetchCostCenters(searchTerm)
            .pipe(
              map((costCentersPageResponse: PageResponse<CostCenterListItem>) =>
                CostCentersActions.fetchCostCentersSuccess({ costCentersPageResponse }),
              ),
            ),
        onError: () => {
          this.notificationService.toastDanger('Fehler beim Abrufen der Kostenstellen');
          return CostCentersActions.fetchCostCentersError();
        },
      }),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly costCentersService: CostCentersService,
    private readonly notificationService: NotificationService,
  ) {}
}
